<template>
  <div class="d-flex flex-md-row mt-2 flex-column-reverse">
    <div class="col-md-9 nopadding">
      <no-access-info-card v-if="showNoAccessInfoCard" />
      <div
        v-else-if="videos.meta && videos.data"
      >
        <div>
          <AudioVideoCard
            v-for="(video) in videos.data"
            :key="video.id"
            :item="video.attributes"
            :can-show-image="showImages"
            @click="$router.push(`/videos/${video.id}`)"
            @onImgLoad="loadedImages+= 1"
          />
        </div>
        <div
          v-if="!videos || !videos.data.length"
          class="d-flex align-items-center justify-content-center"
        >
          <b-img
            src="@/assets/images/pages/course/empty-list.svg"
            alt="Buypass"
            class="d-inline-block mt-5"
          />
        </div>
        <div class="d-flex align-items-center justify-content-center w-100">
          <b-pagination
            v-if="videos.meta.pagination.total > videos.meta.pagination.per_page"
            v-model="currentPage"
            :per-page="videos.meta.pagination.per_page"
            :total-rows="videos.meta.pagination.total"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
    <div class="col-md-3 pl-md-1 pr-md-0 p-0">
      <b-input-group class="input-group-merge mb-1">
        <b-input-group-prepend
          class="no-border"
          is-text
        >
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>

        <b-form-input
          v-model="search"
          type="search"
          class="no-border"
          :placeholder="$t('Search...')"
        />
      </b-input-group>
      <b-card
        v-if="categories.length"
        body-class="p-1"
      >
        <div class="mb-1">
          <h4 class="font-weight-bolder">
            {{ $t("Category") }}
          </h4>
          <b-form-checkbox
            v-for="category in translate(categories, this.$i18n.locale)"
            :key="category.id"
            v-model="selectedCategories"
            :value="category.id"
            class="custom-control-primary mt-1"
          >
            <h5>{{ category.name }}</h5>
          </b-form-checkbox>
        </div>
      </b-card>
    </div>
  </div>

</template>
<script>
import {
  BPagination, BImg, BInputGroup, BInputGroupPrepend, BFormInput, BCard, BFormCheckbox,
} from 'bootstrap-vue'

import { debounce } from 'vue-debounce'

import NoAccessInfoCard from '@/components/NoAccessInfoCard.vue'
import AudioVideoCard from '@/components/AudioVideoCard.vue'

export default {
  components: {
    BPagination,
    BImg,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BCard,
    BFormCheckbox,

    NoAccessInfoCard,
    AudioVideoCard,
  },
  data() {
    return {
      directions: [
        {
          name: 'Recent',
          value: 'asc',
        },
        {
          name: 'Old',
          value: 'desc',
        },
      ],
      loadedImages: 0,
    }
  },
  computed: {
    showImages() {
      return this.loadedImages >= this.videos.data.filter(el => el.attributes.thumbnail).length
    },
    userPermissions() {
      return this.$store.getters['auth/permissions']
    },
    width() {
      return window.innerWidth
    },
    query() {
      return this.$store.getters['videos/query']
    },
    search: {
      get() {
        return this.$store.getters['videos/search']
      },
      // eslint-disable-next-line
      set: debounce(function(search) {
        this.$store.commit('videos/setSearch', search)
      }, 1000),
    },
    videos() {
      return this.$store.getters['videos/videos']
    },
    categories() {
      return this.$store.getters['videos/categories']
    },
    currentPage: {
      get() {
        return this.$store.getters['videos/pageNumber']
      },
      set(pageNumber) {
        this.$store.commit('videos/setPageNumber', pageNumber)
      },
    },
    selectedCategories: {
      get() {
        return this.$store.getters['videos/selectedCategories']
      },
      set(selectedCategories) {
        this.$store.commit('videos/setSelectedCategories', selectedCategories)
      },
    },
    direction: {
      get() {
        return this.$store.getters['videos/direction']
      },
      set(direction) {
        this.$store.commit('videos/setDirection', direction)
      },
    },
    user() {
      return this.$store.getters['auth/user']
    },
    representACompanyAdmin() {
      return 'id' in this.user.active_business_profile && this.user.active_business_profile.role === 'admin'
    },
    showNoAccessInfoCard() {
      if (this.representACompanyAdmin) return false
      if (!this.checkPermission('VIDEO_READ', this.userPermissions)) return true
      return false
    },
  },
  watch: {
    // eslint-disable-next-line
    '$route.query.filter': {
      handler() {
        this.loadedImages = 0
        this.$store.dispatch('videos/fetchVideos')
      },
      deep: true,
    },
    query: {
      handler(query) {
        this.updateQuery(query)
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.showNoAccessInfoCard) {
      this.$store.dispatch('videos/fetchCategories')
      if (this.$route.query.filter) {
        this.$store.commit('videos/setQuery', JSON.parse(this.$route.query.filter))
      }
      if (this.selectedCategory) {
        this.$store.dispatch('videos/fetchVideosByCategory')
      } else {
        this.$store.dispatch('videos/fetchVideos')
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('videos/clearFilters')
  },
  methods: {
    selectCategory(category) {
      this.selectedCategory = category
      this.currentPage = 1
    },
    setDirection(direction) {
      this.direction = direction
      this.currentPage = 1
    },
    updateQuery(query) {
      this.$router.push({
        path: '/videos',
        query: { filter: JSON.stringify(query) },
      }).catch(() => {})
    },
  },
}
</script>
